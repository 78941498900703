import React, { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [cookie] = useCookies(['user']);
    // Data Cookie
    let username = cookie?.user?.username;
    let roles = cookie?.user?.roles;
    let token = cookie?.user?.token;
    let refreshToken = cookie?.user?.refreshToken;

    // Init Context Auth (Reload Auth Provider with Cookie)
    const [auth, setAuth] = useState({username, roles, token, refreshToken});

    // Loader
    const [isLoading, setIsLoading] = useState(false);

    // Flash Message
    const [flash, setFlash] = useState({});
    const [showFlash, setShowFlash] = useState(false);

    return (
        <AuthContext.Provider value={{ auth, setAuth, flash, setFlash, showFlash, setShowFlash, isLoading, setIsLoading }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
