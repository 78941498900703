import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';

const BoosterOpen = () => {
    const { setIsLoading } = useAuth();
    const [boosterCards, setBoosterCards] = useState([]);
    const [displayBooster, setDisplayBooster] = useState(false);

    const openBooster = async () => {
        try {     
            const result = await axios.get('/booster/open', { withCredentials: false })
            setBoosterCards(result.data.cards)
        } catch (error) {
            console.log(error.response)
        }
        setIsLoading(false);
        setDisplayBooster(true)
    };

    const setDefaultImage = async(e) => {
        e.target.src ='/images/default-tcg.png'
    }

    useEffect(() => {
        setDisplayBooster(false)
        setIsLoading(true)
        openBooster();
    }, []);

    const cards = boosterCards.map((boosterCard, key) =>
        <div className='d-flex flex-grow-1 justify-content-center p-2' key={key}><img style={{width:'150px'}} src={`/images/${boosterCard}.png`} alt={boosterCard} onError={setDefaultImage}></img></div>
    );

    return ( displayBooster &&
        <div className='d-flex flex-wrap'>
            {cards}
        </div>
    );
};

export default BoosterOpen;
