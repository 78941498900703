import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import BoosterOpen from './pages/Guest/BoosterOpen';
import NotFoundPage from './pages/Error/NotFound';
import Loader from './components/Loader';

const App = () => {
    return (
        <>
            <Routes>
                {/* Public Routes */}
                <Route path="/booster/open" element={ <BoosterOpen/> }></Route>

                {/* User Routes */}
                <Route element={<RequireAuth allowedRoles={['ROLE_USER']} />}>
                </Route>

                {/* Seller Routes */}
                <Route element={<RequireAuth allowedRoles={['ROLE_SELLER']} />}>
                </Route>

                {/* Routes Error */}
                <Route path='/*' element={ <NotFoundPage/> }/>
            </Routes>

            <Loader />
        </>
    );
}

export default App;
